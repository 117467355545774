<script>
export default {
    /**
     * Inject a form parent to the given form item
     */
    inject: {
        parentForm: {
            default: ''
        }
    }
};
</script>
