<script>
export default {
    methods: {
        /**
         * Handler for focus event
         */
        focusInputRef() {
            this.$refs.input.focus();
        }
    }
}
</script>